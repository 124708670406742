import _ from "lodash";
import config from "../config";

export const getViewportSize = {
  width: Math.max(
    document.documentElement.clientWidth || 0,
    window.innerWidth || 0
  ),
  height: Math.max(
    document.documentElement.clientHeight || 0,
    window.innerHeight || 0
  ),
};

export const isMobile = () => getViewportSize.width <= config.breakpoints[0];

export const getBoxSize = () => {
  if (!isMobile()) {
    return config.defaultSize;
  }

  let width;
  let height;

  height = getViewportSize.height;
  width = (height * config.aspectRatio.width) / config.aspectRatio.height;

  if (width > getViewportSize.width) {
    width = getViewportSize.width;
    height = (width * config.aspectRatio.height) / config.aspectRatio.width;
  }

  return { width, height };
};

export const calculateRatio = (value) => {
  const factor = getBoxSize().width / config.defaultSize.width;

  return value * factor;
};

export const getQueryStrings = () => {
  let assoc = {};

  const decode = (s) => {
    return decodeURIComponent(s.replace(/\+/g, " "));
  };

  const queryString = window.location.search.substring(1);
  const keyValues = queryString.split("&");

  for (var i in keyValues) {
    const key = keyValues[i].split("=");

    if (key.length > 1) {
      assoc[decode(key[0])] = decode(key[1]);
    }
  }

  return assoc;
};

export const resetVideoPlayback = ({ videoRefs }) => {
  _.forEach(videoRefs, (ref) => {
    ref.current.pause();
    ref.current.currentTime = 0;
  });
};
