export default {
  avatar: "https://i.imgur.com/XcZvK0j.jpg",
  name: "Tiscali",
  conversion: {
    value: 10.8,
    currency: "EUR",
  },
  pages: [
    {
      id: "24ubfe7s",
      style: {
        backgroundColor: "#5f57a1",
      },
      blocks: [
        {
          type: "image",
          props: {
            url: "https://i.imgur.com/oo8Y4Ji.jpg",
            layout: {
              top: 0,
              width: "100%",
            },
          },
        },
        {
          type: "video",
          props: {
            orientation: "portrait",
            url: "https://i.imgur.com/LyI6NTh.mp4",
          },
        },
        {
          type: "text",
          props: {
            text:
              "🚀 Vuoi navigare in internet senza alcun problema di connessione? 📶",
            layout: {
              top: 380,
              left: 20,
              width: 350,
              fontSize: 20,
              fontWeight: 800,
              color: "white",
              rotate: -5,
            },
          },
        },
        {
          type: "buttons",
          props: {
            buttons: [
              {
                text: "Magari! 😍",
                class: "animate__animated animate__pulse animate__infinite",
                layout: {
                  padding: 10,
                },
              },
              {
                text: "Finalmente! 👍",
                class: "animate__animated animate__pulse animate__infinite",
                layout: {
                  padding: 10,
                },
              },
            ],
            layout: {
              top: 480,
            },
          },
        },
      ],
    },
    {
      id: "jn64353fnids",
      style: {
        backgroundColor: "#5f57a1",
      },
      blocks: [
        {
          type: "video",
          props: {
            orientation: "portrait",
            url: "https://i.imgur.com/ent44UD.mp4",
          },
        },
        {
          type: "text",
          props: {
            text: "Hai già Tiscali Ultrainternet Wireless a casa?",
            layout: {
              top: 380,
              left: 30,
              width: 385,
              fontSize: 24,
              fontWeight: 800,
              color: "white",
              rotate: -5,
            },
          },
        },
        {
          type: "buttons",
          props: {
            buttons: [
              {
                text: "Sì!",
                class: "animate__animated animate__pulse animate__infinite",
                toast: {
                  message:
                    "Ci dispiace, la promozione è disponibile soltanto per chi non è ancora cliente Tiscali!",
                  appearance: "error",
                },
                layout: {
                  padding: 10,
                },
              },
              {
                text: "No",
                class: "animate__animated animate__pulse animate__infinite",
                layout: {
                  padding: 10,
                },
              },
            ],
            layout: {
              top: 480,
            },
          },
        },
      ],
    },
    {
      id: "gfd7gydhw",
      style: {
        backgroundColor: "#5f57a1",
      },
      blocks: [
        {
          type: "text",
          props: {
            text:
              "Ottimo, per poter verificare la tua copertura e darti tutte le informazioni su Tiscali Ultrainternet Wireless, avrei bisogno del tuo consenso alla nostra privacy",
            layout: {
              top: 200,
              left: 25,
              width: 350,
              fontSize: 20,
              rotate: 0,
              textAlign: "center",
              color: "white",
            },
          },
        },
        {
          type: "buttons",
          props: {
            buttons: [
              {
                text: "Visualizza privacy policy",
                url:
                  "https://www.tiscali.it/privacy/informativa_policy_privacy.html",
                layout: {
                  fontWeight: 300,
                  backgroundColor: "transparent",
                  padding: 10,
                  border: "none",
                  fontSize: 12,
                  color: "#d5d5d5",
                },
              },
              {
                text: "Acconsento 👍",
                class: "animate__animated animate__pulse animate__infinite",
                layout: {
                  backgroundColor: "#4bb238",
                  color: "#fff",
                  border: "3px solid #fff",
                  borderRadius: 0,
                },
              },
              {
                text: "Non acconsento",
                toast: {
                  message:
                    "Ci dispiace che tu abbia negato il consenso. SENZA consenso non potremo contattarti per inviarti la promozione",
                  appearance: "error",
                },
                layout: {
                  fontWeight: 300,
                  backgroundColor: "transparent",
                  padding: 10,
                  border: "none",
                  fontSize: 12,
                  color: "#d5d5d5",
                },
              },
            ],
            layout: {
              top: 440,
            },
          },
        },
      ],
    },
    {
      id: "102jsdf34",
      style: {
        backgroundColor: "#5f57a1",
      },
      blocks: [
        {
          type: "video",
          props: {
            orientation: "portrait",
            url: "https://i.imgur.com/YUyrXel.mp4",
          },
        },
        {
          type: "text",
          props: {
            text: `✅ Internet illimitato fino a 100 MEGA

✅ Senza Linea Fissa

✅ Modem Wi-Fi Gratis

✅ Senza Vincoli

✅ Zero Costi Nascosti

📺 2 Mesi GRATIS di Infinity TV per goderti il Meglio del Cinema e delle Serie TV! 🎞

&nbsp;

Tutto a soli 26,95€ per SEMPRE.
            `,
            layout: {
              top: 345,
              left: 20,
              width: 355,
              fontSize: 15,
              color: "white",
              lineHeight: 1.3,
              paddingLeft: 7,
              paddingRight: 7,
              paddingTop: 7,
              paddingBottom: 7,
              borderRadius: 5,
              backgroundColor: "rgba(0, 0, 0, 0.5)",
            },
          },
        },
        {
          type: "buttons",
          props: {
            buttons: [
              {
                text: "Procedi",
                class: "animate__animated animate__pulse animate__infinite",
                layout: {
                  padding: 10,
                  width: 100,
                },
              },
            ],
            layout: {
              top: 550,
            },
          },
        },
      ],
    },
    {
      id: "3mfsd9fsdj8",
      style: {
        backgroundColor: "#5f57a1",
      },
      blocks: [
        {
          type: "text",
          props: {
            text:
              "👍 Perfetto! Verifichiamo la velocità disponibile al tuo indirizzo 📶",
            layout: {
              top: 86,
              left: 70,
              width: 240,
              textAlign: "center",
              fontSize: 18,
              color: "white",
            },
          },
        },
        {
          type: "input",
          props: {
            field: "phone",
            text: "Inserisci il tuo numero di telefono qua:",
            inputType: "phone",
            placeholder: "es.: 3401234567",
            countryCode: "+39",
            layout: {
              top: 200,
            },
          },
        },
        {
          type: "image",
          props: {
            url:
              "https://media3.giphy.com/media/JIX9t2j0ZTN9S/giphy.gif?cid=ecf05e47wn89yjvfz47aam97lttm8aftu82pbdmrp38u59j8&rid=giphy.gif",
            layout: {
              top: 303,
              left: 0,
              width: 393,
            },
          },
        },
      ],
    },
    {
      id: "gdf67shfsf2",
      style: {
        backgroundColor: "#5f57a1",
      },
      blocks: [
        {
          type: "text",
          props: {
            text: "Abbiamo quasi finito, prima però presentiamoci!",
            layout: {
              lineHeight: 1.3,
              top: 86,
              left: 45,
              width: 300,
              textAlign: "center",
              fontSize: 22,
              color: "white",
            },
          },
        },
        {
          type: "input",
          props: {
            field: "first_name",
            text: "Scrivi il tuo nome e cognome qui in basso! 👇",
            inputType: "text",
            placeholder: "es.: Mario Rossi",
            layout: {
              top: 200,
            },
          },
        },
        {
          type: "image",
          props: {
            url:
              "https://media2.giphy.com/media/aNqEFrYVnsS52/200w.webp?cid=ecf05e47ccaf6c4d79ba2586019fac8b437256ef26e14012&rid=200w.webp",
            layout: {
              top: 400,
              left: 0,
              width: 393,
            },
          },
        },
      ],
    },
    {
      id: "456jn5i43n5",
      style: {
        backgroundColor: "#5f57a1",
      },
      disableBackNavigation: true,
      blocks: [
        {
          type: "video",
          props: {
            orientation: "portrait",
            url: "https://i.imgur.com/4hroJk1.mp4",
          },
        },
        {
          type: "text",
          props: {
            text:
              "Perfetto, un nostro operatore provvederà a contattarti entro pochi minuti!",
            layout: {
              top: 380,
              left: 46,
              width: 300,
              fontSize: 20,
              rotate: 0,
              lineHeight: 1.3,
              color: "white",
              textAlign: "center",
              paddingLeft: 15,
              paddingRight: 15,
              paddingTop: 15,
              paddingBottom: 15,
              backgroundColor: "rgba(0, 0, 0, 0.5)",
            },
          },
        },
        {
          type: "buttons",
          props: {
            buttons: [
              {
                text: "Riassunto offerta",
                layout: {
                  padding: 15,
                },
              },
            ],
            layout: {
              top: 535,
            },
          },
        },
        {
          type: "webhook",
          props: {
            url:
              "https://brain-api.spinup.media/track/campaign/c6ca8ba6-f712-4b65-b0f5-931b2a725fd5/lead",
            method: "POST",
            params: {
              client_id: "8bd09b24-3589-4be6-a90f-95c72ca116f1",
              privacy: "true",
              customer_source: "advid",
            },
          },
        },
      ],
    },
    {
      id: "mfk34j90wefs",
      style: {
        backgroundColor: "#5f57a1",
      },
      blocks: [
        {
          type: "text",
          props: {
            text: "Ecco un riassunto dell'offerta Ultrainternet Wireless! 😃",
            layout: {
              top: 85,
              left: 60,
              width: 280,
              fontSize: 22,
              fontWeight: 800,
              rotate: -3,
              color: "white",
            },
          },
        },
        {
          type: "text",
          props: {
            text: `✅ Internet illimitato fino a 100 MEGA

✅ Senza Linea Fissa

✅ Modem Wi-Fi Gratis

✅ Senza Vincoli

✅ Zero Costi Nascosti

📺 2 Mesi GRATIS di Infinity TV per goderti il Meglio del Cinema e delle Serie TV! 🎞
            `,
            layout: {
              top: 260,
              left: 40,
              width: 300,
              fontSize: 20,
              color: "white",
            },
          },
        },
      ],
    },
  ],
};
