import React from "react";
import _ from "lodash";
import { Box } from "rebass";
import { css, cx } from "emotion";
import { useToasts } from "react-toast-notifications";
import { calculateRatio, resetVideoPlayback } from "../../../utils";
import useStories from "../../../hooks/stories";
import { shake } from "../../../styles/animations";

const Buttons = ({ buttons, layout }) => {
  const { addToast } = useToasts();
  const {
    showInteractionFeedback,
    currentStoryIndex,
    videoRefs,
    pages,
    dispatch,
  } = useStories();

  const style = css`
    position: ${layout.top || layout.left ? "absolute" : "relative"};
    top: ${layout.top ? `${calculateRatio(layout.top)}px` : "initial"};
    left: ${layout.left ? `${calculateRatio(layout.left)}px` : "initial"};
    font-size: ${calculateRatio(layout.fontSize || 16)}px;
    width: ${layout.width
      ? `${calculateRatio(layout.width)}px`
      : `${calculateRatio(280)}px`};
    transform: rotate(${layout.rotate || 0}deg);

    ${showInteractionFeedback &&
    `
      animation: ${shake} 0.5s ease;
    `}
  `;

  return (
    <Box className={style}>
      {buttons.map((button, index) => (
        <button
          key={index}
          className={cx(
            css`
              display: block;
              width: 100%;
              padding: ${calculateRatio(
                _.get(button, "layout.padding") || 20
              )}px;
              font-size: ${calculateRatio(
                _.get(button, "layout.fontSize") || 20
              )}px;
              margin-bottom: ${calculateRatio(10)}px;
              font-weight: 600;
              border: ${_.get(button, "layout.border") || "3px solid #cd8800"};
              border-radius: ${typeof _.get(button, "layout.borderRadius") !==
              "undefined"
                ? button.layout.borderRadius
                : 50}px;
              background-color: ${_.get(button, "layout.backgroundColor") ||
              "#ffba00"};
              font-weight: ${_.get(button, "layout.fontWeight") || "500"};
              color: ${_.get(button, "layout.color") || "#282822"};
              outline: none;
              cursor: pointer;
            `,
            button.class
          )}
          onClick={() => {
            resetVideoPlayback({
              videoRefs,
            });

            dispatch({
              type: "IS_VIDEO_PLAYING_TOGGLE",
              payload: false,
            });

            if (button.url) {
              return window.open(button.url, "_blank");
            }

            if (button.toast) {
              return addToast(button.toast.message, {
                appearance: button.toast.appearance,
                autoDismiss: true,
              });
            }

            const nextStoryId = _.get(pages[currentStoryIndex + 1], "id");

            if (videoRefs[nextStoryId] && videoRefs[nextStoryId].current) {
              videoRefs[nextStoryId].current.play();

              dispatch({
                type: "IS_VIDEO_PLAYING_TOGGLE",
                payload: true,
              });
            }

            dispatch({
              type: "PAGE_NEXT",
            });
          }}
        >
          {button.text}
        </button>
      ))}
    </Box>
  );
};

export default Buttons;
